import React, { useEffect } from 'react';
import '../styles/globals.css';
import { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from 'next-themes';
import { init } from '@socialgouv/matomo-next';

function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    if (
      process.env.NEXT_PUBLIC_RELEASE_TAG &&
      process.env.NEXT_PUBLIC_RELEASE_TAG.match(/^v[0-9]+\.[0-9]+\.[0-9]+$/)
    ) {
      init({
        url: process.env.NEXT_PUBLIC_MATOMO_URL,
        siteId: process.env.NEXT_PUBLIC_MATOMO_SITE_ID,
      });
    }
  }, []);
  return (
    <ThemeProvider attribute="class">
      <Component {...pageProps} />
    </ThemeProvider>
  );
}

export default appWithTranslation(App);
